<script lang="ts">
	import { ActiveServer, User } from "../../store";
	import { ClientJobState } from "../../types/ClientJob.type";
	import { delay } from "../../utils/delay";
	import { DummyJob } from "../../lib/jobs/DummyJob";
	import { error, success } from "../../toaster";
	import { JobManager } from "../../lib/jobs/JobManager";
	import { Layout, setLayout } from "../../layout/layout-context/layout-context";
	import { navigate } from "svelte-routing";
	import { preloadVideo } from "../../utils/preload-video";
	import { ServerFeatureFlags, UserRole } from "../../types/Enums";
	import Api from "../../Api";
	import BootupScreen from "../../lib/BootupScreen.svelte";
	import Checkbox from "../../layout/Checkbox.svelte";
	import NotFoundPage from "../NotFoundPage.svelte";
	import OrderOverviewWidget from "./OrderOverviewWidget.svelte";
	import OrderPackageHead from "./OrderPackageHead.svelte";
	import OrderPackageWidget from "./OrderPackageWidget.svelte";
	import OrderPackageWidgetMobile from "./OrderPackageWidgetMobile.svelte";
	import OrderPayment from "./OrderPayment.svelte";
	import OrderSoftwareSelect from "./OrderSoftwareSelect.svelte";
	import OrderUserSelector from "./OrderUserSelector.svelte";
	import Protect from "../../lib/Protect.svelte";
	import { t } from "../../lib/locale";
	import { ln2br } from "../../utils/formatString";
	import { hasFlag } from "../../utils/flag-utils";
	import OrderFormMinecraftVersion from "./specials/OrderFormMinecraftVersion.svelte";

	export let packageId: string;
	export let layout: Layout = Layout.ANONYMOUS;

	setLayout(layout);

	document.title = `${$t('order.title')} - Helkor.eu`;

	// Loaders
	let loading = true;
	let product: Omit<Product, 'eggs'> & {eggs: WithPivot<ServerEgg>[]} | undefined = undefined;
	async function loadProduct() {
		try {
			loading = true;
			const response = await Api.call(`product/${packageId}`);
			if (response.success && !response.product.disabled) {
				product = response.product;
				if (product?.eggs?.length === 1) {
					orderData.egg = product.eggs[0].id;
				}
			}
		} finally {
			loading = false;
		}

	}
	loadProduct();

	// Order data + save/load
	let orderData = {
		product: parseInt(packageId),
		name: "",
		node: undefined as number | undefined,
		user: $User?.id ?? undefined,
		is_testing_server: false,
		egg: undefined as number | undefined,
		env: {} as Record<string, string>,
	};

	let selectedEgg: ServerEgg | undefined;
	$: selectedEgg = product?.eggs.find(egg => egg.id === orderData.egg);

	function saveFormFields(order: typeof orderData) {
		sessionStorage.setItem('orderData', JSON.stringify({
			order,
		}));
	}
	function loadFormFiels() {
		const data = sessionStorage.getItem('orderData');
		if (!data) return;

		try {
			const savedData = JSON.parse(data);
			if (savedData.order.product.toString() !== packageId) {
				return;
			}

			orderData = {
				...orderData,
				...savedData.order,
			}

		} finally {
			return;
		}

	}
	loadFormFiels();
	$: saveFormFields(orderData);

	// Validation
	function validateFormAndReturnError(_dependencies: any[] = []) {
		if (orderData.name.length < 3) return $t('order.error.name.tooShort');
		if (orderData.name.length > 25) return $t('order.error.name.tooLong');
		if (!orderData.user) return $t('order.error.needLogin');
		if (!orderData.product || !product || orderData.product !== product.id) return $t('order.error.productNotFound');
		if (!orderData.egg) return $t('order.error.softwareNotFound');
		if (!$User || $User.credits < product.minimum_credits) return $t('order.error.notEnoughCredits');
		return undefined;
	}
	let formError: string | undefined = $t('order.error.notValidated');
	$: formError = validateFormAndReturnError([
		orderData.name,
		orderData.user,
		orderData.product,
		orderData.egg,
		product,
		$User?.id,
	]);

	// Submit
	let submited = false;
	async function submitOrder() {
		if (validateFormAndReturnError() !== undefined) return;
		if (loading) return;
		if (submited) return;

		submited = true;
		sessionStorage.removeItem('orderData');

		const dummyJob = new DummyJob();
		dummyJob.title = $t('order.job.title');
		dummyJob.message = $t('order.job.message');
		dummyJob.cancelable = false;
		JobManager.runJobPromise(dummyJob);

		try {
			const response = await Api.call('servers/create', {
				name: orderData.name,
				product: orderData.product,
				node: orderData.node,
				egg: orderData.egg,
				user: orderData.user,
				env: orderData.env,
				is_testing_server: orderData.is_testing_server,
			});

			if (response.success) {
				await delay(3000);
				await User.fetch();
				await ActiveServer.fetch(response.server.identifier ?? response.server.id);
				dummyJob.state = ClientJobState.FINISHED;
				success($t('order.job.success'));
				navigate('/server/' + (response.server.identifier ?? response.server.id));
				return;
			}
			dummyJob.state = ClientJobState.FAILED;
			error(response.message ?? $t('order.job.error'));
		} catch {
			dummyJob.state = ClientJobState.FAILED;
		} finally {
			submited = false;
		}
	}

	let preloadedBuildVideo: string;
	(async function(){
		preloadedBuildVideo = await preloadVideo('/video/construction.mp4');
	})();
</script>

{#if !loading && !product}
	<NotFoundPage />
{:else if submited}
	<BootupScreen videoSource={preloadedBuildVideo} title={$t('order.job.title')} />
{:else}

<h1>{$t('order.title')}</h1>
<div class="order">
	<div class="order-form">
		<div class="package-detail--desktop">
			<OrderPackageHead />
			<OrderPackageWidget {product} />
		</div>
		<div class="package-detail--mobile">
			<OrderPackageWidgetMobile {product} />
		</div>

		<div class="box">
			<h2>{$t('order.field.server.name.title')}</h2>
			<p>{$t('order.field.server.name.description')}</p>
			<div class="input-group">
				<div class="input-group-prepend">
					<i class="fa-solid fa-pen"></i>
				</div>
				<input
					type="text"
					placeholder={$t('order.field.server.name.placeholder')}
					minlength={3}
					maxlength={25}
					required
					bind:value={orderData.name}
				/>
			</div>
			<div class="input-validate">
				<div
					class="length-counter"
				>
					{orderData.name.length} / 25
				</div>
			</div>
		</div>

		<Protect roles={[UserRole.ADMIN]}>
			<div class="box box--admin-only">
				<h2>{$t('order.field.node.title')}</h2>
				<p>
					{@html ln2br($t('order.field.node.description'))}
				</p>
				<select bind:value={orderData.node}>
					<option value={undefined}>
						{$t('order.field.node.placeholder')}
					</option>
					{#each product?.nodes ?? [] as node}
						<option value={node.id}>{node.name}</option>
					{/each}
				</select>
			</div>
		</Protect>

		<div class="box">
			<OrderUserSelector bind:value={orderData.user} />
		</div>


		<Protect roles={[UserRole.ADMIN]}>
			<div class="box box--admin-only">
				<h2>{$t('order.field.demonstration.title')}</h2>
				<p>
					{@html ln2br($t('order.field.demonstration.description'))}
				</p>
				<Checkbox bind:checked={orderData.is_testing_server} label={$t('order.field.demonstration.placeholder')} />
			</div>
		</Protect>

		<div class="box">
			<OrderPayment min={product?.minimum_credits ?? 0} userId={orderData.user} />
		</div>

		{#if product?.eggs}
			{#if product.eggs.length > 1}
				<div class="box">
					<h2>{$t('order.field.software.title')}</h2>
					<p>
						{@html ln2br($t('order.field.software.description'))}
					</p>
					<OrderSoftwareSelect
						options={product.eggs}
						bind:value={orderData.egg}
					/>
				</div>
			{/if}
		{/if}

		{#if selectedEgg && hasFlag(selectedEgg.feature_flags, ServerFeatureFlags.MINECRAFT)}
			<div class="box">
				<h2>Verze hry</h2>
				<p>Vyberte si platformu a verzi serveru, které Vám máme na server předinstalovat.</p>
				<OrderFormMinecraftVersion bind:env={orderData.env} />
			</div>
		{/if}

		<div class="actions">
			<button
				class="button secondary order-button"
				disabled={!!formError}
				data-balloon-pos="up"
				aria-label={formError}
				on:click={submitOrder}
			>
				<i class="fas fa-check"></i>
				{$t('order.submit')}
			</button>
		</div>
	</div>
	<div class="order-overview">
		<OrderOverviewWidget
			packageName={product?.name ?? $t('loading')}
			packagePrice={product?.price ?? -1}
			packageCpu={product?.cpu ?? 0}
			packageRam={product?.memory ?? 0}
			packageDisk={product?.disk ?? 0}
			serverName={orderData.name.trim()}
			serverNodeName={orderData.node === undefined ? '' : product?.nodes.find(node => node.id.toString() === orderData.node?.toString())?.name ?? ''}
			serverIsTesting={orderData.is_testing_server}
			serverSoftware={(product?.eggs ?? []).find(egg => egg.id === orderData.egg)?.name ?? ''}

			error={formError}
			submit={submitOrder}

			creditPrice={0}
		/>
	</div>
</div>

{/if}

<style>
	.order {
		display: flex;
		gap: 1rem;
	}
	.order-form {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-top: 1.5rem;
	}
	.order-overview {
		flex: 0 0 30%;
	}

	.box {
		border: none;
	}

	p {
		color: var(--var-color-text-light);
		margin: .5rem 0;
	}

	@media (max-width: 1600px) {
		.order {
			flex-direction: column;
		}
		.order-overview {
			flex: 1;
		}
		.order-button {
			display: none;
		}
	}

	.package-detail--mobile {
		display: none;
	}
	@media (max-width: 950px) {
		.package-detail--desktop {
			display: none;
		}
		.package-detail--mobile {
			display: block;
		}
	}
</style>
