<script lang="ts">
	import NavHeader from "./NavHeader.svelte";
	import NavItem from "./NavItem.svelte";
	import { ActiveServer, User } from '../store';
	import { Link, link } from "svelte-routing";
	import Protect from "../lib/Protect.svelte";
	import { ServerFeatureFlags, UserRole } from "../types/Enums";
	import Api from "../Api";
	import { success } from "../toaster";
	import DebugOnly from "../lib/DebugOnly.svelte";
	import { localDeviceSettings } from "../lib/local-device-settings";
	import { hasFlag } from "../utils/flag-utils";
	import { t } from "../lib/locale";

	let hidden = true;

	const updateActiveServer = () => {
		if ($ActiveServer && !location.pathname.includes('/server/') && !$localDeviceSettings.pinServer) {
			hidden = true;
			setTimeout(() => {
				ActiveServer.set(undefined);
			}, 250);
		}
		return {
			style: "display:none;"
		};
	}

	async function toggleServerPin() {
		localDeviceSettings.update(settings => {
			settings.pinServer = !settings.pinServer;
			if (!settings.pinServer) {
				updateActiveServer();
			}
			return settings;
		});
	}

	$: {
		if ($ActiveServer) {
			hidden = false;
		}
	}
</script>

<Link to='/server' getProps={updateActiveServer}>Location Detection</Link>


{#if $ActiveServer}
<div class="menu-show-animation" class:hiding={hidden} >
	<NavHeader text={($ActiveServer?.meta?.name ?? '')}>
		<button class="pin-icon" class:active={$localDeviceSettings.pinServer} on:click={toggleServerPin} title="Připnout aktivní server">
			<i class="fa-solid fa-thumbtack"></i>
		</button>
	</NavHeader>

	{#if $ActiveServer.container?.is_installing}
		<NavItem icon="fa-solid fa-person-digging" text={$t('menu.server.installing')} href="/server/{$ActiveServer.meta?.identifier}/terminal" exact />
	{:else if $ActiveServer.container?.is_transferring}
		<NavItem icon="fa-solid fa-truck-moving" text={$t('menu.server.transferring')} href="/server/{$ActiveServer.meta?.identifier}/is_transferring" exact />
	{:else if $ActiveServer.container?.is_suspended}
		<NavItem icon="fa fa-pause" text={$t('menu.server.suspended')} href="/server/{$ActiveServer.meta?.identifier}/terminal" exact />
		<NavItem icon="fa fa-gears" text={$t('menu.server.settings')} href="/server/{$ActiveServer.meta?.identifier}/settings" />
	{:else}
		<NavItem icon="fa fa-terminal" text={$t('menu.server.terminal')} href="/server/{$ActiveServer.meta?.identifier}/terminal" exact />
		<NavItem icon="fa fa-file" text={$t('menu.server.files')} href="/server/{$ActiveServer.meta?.identifier}/files" />

		{#if ActiveServer.hasFeature(ServerFeatureFlags.MINECRAFT_PLUGIN_INSTALLER) || ActiveServer.hasFeature(ServerFeatureFlags.MINECRAFT)}
			<NavItem icon="fa-solid fa-puzzle-piece" text={$t('menu.server.mc.plugins')} href="/server/{$ActiveServer.meta?.identifier}/minecraft/addons" />
		{/if}

		{#if ($ActiveServer?.container?.feature_limits?.databases) !== 0}
			<NavItem icon="fa fa-database" text={$t('menu.server.databases')} href="/server/{$ActiveServer.meta?.identifier}/databases" />
		{/if}

		<NavItem icon="fa fa-clock" text={$t('menu.server.cron')} href="/server/{$ActiveServer.meta?.identifier}/schedules" />

		{#if ($ActiveServer?.container?.feature_limits?.backups) !== 0}
			<NavItem icon="fa fa-floppy-disk" text={$t('menu.server.backups')} href="/server/{$ActiveServer.meta?.identifier}/backups" />
		{/if}

		{#if ActiveServer.hasFeature(ServerFeatureFlags.MARKETPLACE) && ($User?.id === $ActiveServer.meta.user_id || $User?.role === UserRole.ADMIN)}
			<NavItem icon="fa-solid fa-shop" text={$t('menu.server.marketplace')} href="/server/{$ActiveServer.meta?.identifier}/marketplace" badge="Nové" />
		{/if}

		{#if (ActiveServer.hasFeature(ServerFeatureFlags.MINECRAFT_SERVER_LIST) || ActiveServer.hasFeature(ServerFeatureFlags.MINECRAFT)) && $User?.id === $ActiveServer.meta.user_id}
			<NavItem icon="fa-solid fa-bullhorn" text={$t('menu.server.mc.serverlist')} href="/server/{$ActiveServer.meta?.identifier}/minecraft/server-list" />
		{/if}

		<NavItem icon="fa fa-users" text={$t('menu.server.users')} href="/server/{$ActiveServer.meta?.identifier}/users" />

		{#if ActiveServer.hasFeature(ServerFeatureFlags.MINECRAFT)}
			<NavItem icon="fa-solid fa-sliders" text={$t('menu.server.mc.properties')} href="/server/{$ActiveServer.meta?.identifier}/minecraft/server-properties" />
			<NavItem icon="fa-solid fa-hashtag" text={$t('menu.server.mc.version')} href="/server/{$ActiveServer.meta?.identifier}/minecraft/version" />
		{/if}
		<NavItem icon="fa fa-gears" text={$t('menu.server.settings')} href="/server/{$ActiveServer.meta?.identifier}/settings" />
		<!--NavItem icon="fa fa-clock-rotate-left" text="Protokol auditu" href="/server/{server.meta?.identifier}/log" /-->
	{/if}

	<Protect roles={[
		UserRole.ADMIN,
	]}>
		<div class="symbols">
			<a
				href={`https://panel.helkor.eu/server/${$ActiveServer?.meta.identifier}`}
				target="_blank"
				aria-label="Docker console"
				data-balloon-pos="up"
			>
				<i class="fa-brands fa-docker"></i>
			</a>
			<a
				use:link
				href={`/admin/users/edit/${$ActiveServer?.meta.user_id}`}
				aria-label="Vlastník serveru"
				data-balloon-pos="up"
			>
				<i class="fa-solid fa-user"></i>
			</a>
			{#if $User?.id !== $ActiveServer?.meta.user_id}
				<a
					href="#_sudo"
					on:click={() => {
						Api.call('auth/sudo', {
							user: $ActiveServer?.meta.user_id
						})
						.then(response=>{
							if (response.success) {
								localStorage.setItem('sudo_token', response.token);
								User.set(response.user);
								success('SUDO ' + response.user.username);
							}
						})
					}}
					aria-label="SUDO"
					data-balloon-pos="up"
				>
					<i class="fa-solid fa-key"></i>
				</a>
			{/if}
			<a
				use:link
				href={`/admin/server/${$ActiveServer?.meta.id}`}
				aria-label="Admin nastavení"
				data-balloon-pos="up"
			>
				<i class="fa-solid fa-cog"></i>
			</a>
		</div>
	</Protect>
</div>
{/if}

<style lang="scss">
	.pin-icon {
		float: right;
		transform: translateX(100%) rotate(45deg);
		opacity: .3;
		cursor: pointer;
		transition: all .1s ease-in-out;
		background-color: transparent;
		border: none;
		padding: 0;
	}
	.pin-icon:hover {
		opacity: .8;
	}
	.pin-icon.active {
		opacity: 1;
		transform: translateX(100%) rotate(0deg);
	}

	.menu-show-animation {
		animation: menu-show .3s ease-in-out;
	}
	.menu-show-animation.hiding {
		animation: menu-hide .3s ease-in-out;
	}

	@keyframes menu-show {
		0% {
			opacity: 0;
			transform: translateX(-100%);
			max-height: 0;
		}
		100% {
			opacity: 1;
			transform: translateX(0%);
			max-height: 100vh;
		}
	}
	@keyframes menu-hide {
		0% {
			opacity: 1;
			transform: translateX(0%);
			max-height: 100vh;
		}
		100% {
			opacity: 0;
			transform: translateX(-100%);
			max-height: 0;
		}
	}

	.symbols {
		display: flex;
		justify-content: space-evenly;
		margin-top: .4rem;
		padding: .5rem 0;
		border-radius: 5px;
		background: rgba(0,0,0,.3);

		a {
			color: var(--var-color-text-light);
			transition: all .1s ease-in-out;
			&:hover {
				color: var(--color-text-hover);
			}
		}
	}

</style>
